import React, { Component } from 'react'
 
class VideoList extends Component {
  render () {

    return ( 
        <div>
            <h2>EasyCBM Video Tutorials ...</h2>

            <h3>General Topics</h3>

            <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-CBM-Defined_BRT_V3.mp4/CBM Defined/">Curriculum Based Measurement ( CBM ) Defined</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-RTI_V2.mp4/Response To Intervention ( RTI )/">Response To Intervention ( RTI )</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Interventions-2_V2.mp4/Interventions/">Interventions</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Norms_V2.mp4/Percentile Norms/">Percentile Norms</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Percentile-Ranks_V2.mp4/Percentile Ranks/">Percentile Ranks</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Progress-Monitor_V2.mp4/Progress Monitoring/">Progress Monitoring</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Setting-Goals-2_V2.mp4/Setting Goals/">Setting Goals</a>
                </li>
            </ul>

            <h3>Measure Overviews</h3>

            <h4>Reading</h4>

            <ul class="list-group" >
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Measure-Overview_LN-2_V2.mp4/Letter Names ( LN )/">Letter Names ( LN )</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Measure-Overview_LS_V2.mov/Letter Sounds ( LS )">Letter Sounds ( LS )</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Measure-Overview_Seg_V2.mp4/Phoneme Segmenting ( PS )/">Phoneme Segmenting ( PS )</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Measure-Overview_WRF_V2.mp4/Word Reading Fluency ( WRF )/">Word Reading Fluency ( WRF )</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Measure-Overview_PRF_V2.mp4/Passage Reading Fluency ( PRF )/">Passage Reading Fluency ( PRF )</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Measure-Overview_CCSS-Reading_V2.mp4/CCSS Reading ( CCSS )/">CCSS Reading ( CCSS )</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Measure-Overview_Voc_V2.mp4/Vocabulary ( VOCAB )">Vocabulary ( VOCAB )</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Measure-Overview_MCRC-Reading_V2.mp4/Multiple Choice Reading Comprehension ( MCRC )/">Multiple Choice Reading Comprehension ( MCRC )</a>
                </li>
            </ul>

            <h4>Math</h4>   

            <ul class="list-group">

                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Measure-Overview_CCSS-Math_V2.mp4/CCSS Math/">CCSS Math</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Measure-Overview_NCTM_V2.mp4/NCTM Math/">NCTM Math</a>
                </li>
            </ul>
                    
            <h4>Spanish Language</h4>
                        
            <ul class="list-group">

                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Measure-Overview_Spanish-SRF_V2.mp4/Spanish Sentence Reading Fluency/">Spanish Sentence Reading Fluency</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Measure-Overview_Spanish-Syl-Seg_V3.mp4/Spanish Sylable Segmentation/">Spanish Sylable Segmentation</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Measure-Overview_Spanish-Syl-Sounds_V3.mp4/Spanish Sylable Sounds/">Spanish Sylable Sounds</a>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/videos/RV-easyCBM-Measure-Overview_Spanish-WRF_V3.mp4/Spanish Word Reading Fluency/">Spanish Word Reading Fluency</a>
                </li>
            </ul> 

        </div>
    )
  }
}

export default VideoList;