import React, { Component } from 'react'

import VideoList from './VideoList'
import ShowVideo from './ShowVideo'

import { BrowserRouter as Router, Route } from "react-router-dom";
 
class App extends Component {
  render () {

    return (
        <Router>
            <Route path='/' component={VideoList} exact={true} />
            <Route path='/videos/:filename/:description/' component={ShowVideo} />
        </Router>
    )
  }
}

export default App;
