import React, { Component } from 'react'

import ReactPlayer from 'react-player'
 
class ShowVideo extends Component {
  render () {

    const filename = this.props.match.params.filename;
    const description = this.props.match.params.description;

    const m3u8_url = "/v/" + filename + "/ts/playlist.m3u8";

    return (
        <div style={{margin:"auto 0",align:"center"}}>
            <a href="/">Videos Menu</a>
            <h3>Playing: {description}</h3>
            <ReactPlayer style={{align:"center"}} url={m3u8_url} playing controls />
        </div>    
    )
  }
}

export default ShowVideo;